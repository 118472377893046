@import './variables.scss';

.user-quiz-card-container {
  font-weight: 600;

  .card-image {
    height: 250px;
    width: 100%;
    border-radius: 10px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .quiz-status {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .expire-date,
  .quiz-reward {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .quiz-reward {
    margin-top: 10px;
  }

  .quiz-status {
    margin: 3px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .status {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
}

.user-quiz-fill-form-container {
  .quiz-name {
    font-size: 2.5em;
  }

  .quiz-form {
    margin-top: 20px;

    .back-button {
      display: flex;
      gap: 5px;
      padding: 0 15px;

      .icon {
        margin-right: 5px;
      }
    }

    .next-button {
      display: flex;
      gap: 15px;
      align-items: center;

      .icon {
        margin: 5px 0 0 3px;
      }
    }
  }

  .question-body {
    font-size: 1.7em;
    margin-bottom: 3rem;
  }

  .selected-option {
    padding: 10px;
    border: 1px solid rgba(231, 228, 228, 0.2588235294);
    border-radius: 5px;
    margin-bottom: 1rem;
  }

  .mantine-Radio-labelWrapper,
  .mantine-Checkbox-labelWrapper {
    width: 100% !important;
  }
}

.user-quiz-summary-container {
  .quiz-summary-img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    object-fit: cover;
  }

  .question-title {
    margin-top: 3rem;
    margin-bottom: 5px;
    font-size: 1.5em;
  }

  .reply-button {
    margin-top: 2rem !important;
  }

  .try-again-button,
  .share-survey-button {
    margin-top: 0.8rem !important;
    display: flex;
    align-items: center;

    .icon {
      margin-left: 8px;
    }
  }
}

.user-new-quiz-container {
  .map-container {
    height: calc(100vh - 420px);
    width: 100%;
  }

  .locations-list {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 0 0 10px;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  h2 {
    margin-bottom: 0px;
  }

  .location-off-message {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.share-survey-button {
  .icon {
    margin-left: 8px;
  }
}

.link-input {
  width: 450px;
}

.clipboard-icon {
  margin-top: 24px;

  .clipboard {
    height: 33px !important;
    width: 33px !important;
  }
}

.email-dialog-box {
  width: 500px !important;
  display: flex;

  .mantine-Modal-title {
    display: flex;
    align-items: center;
    gap: 5px;

    p {
      font-weight: bold;
      color: var(--primary-color) !important;
    }
  }
}

.flex-email-field {
  display: flex;
  align-items: center;
  gap: 15px;

  input {
    width: 400px;
  }

  .submit-button {
    margin-top: 23px !important;
  }
}
