.publux-container {
  display: flex;
  gap: 40px;
  flex-direction: column;

  h1,
  h2,
  p {
    margin: 0;
  }

  ol,
  ul {
    margin: 0;
    margin-top: 10px;

    li {
      padding-top: 5px;
    }
  }

  .ready-for-register {
    margin: 10px 0 0 25px;

    p {
      padding-top: 5px;
    }
  }

  .publux-heading {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .description {
      padding: 0 50px;
      text-align: center;
      font-size: 18px;
    }
  }

  .flex-lists {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
}
