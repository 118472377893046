@import './variables.scss';

// ************************ Input ************************
.mantine-Input-input {
  background: transparent !important;
}

// ************************ Breadcrumbs ************************
.mantine-Breadcrumbs-breadcrumb {
  .non-active-link {
    color: var(--light-color);
    text-decoration: none;

    &:hover {
      color: var(--primary-color) !important;
    }
  }

  .active-link {
    text-decoration: none;
    color: var(--primary-color) !important;
  }
}

.mantine-Breadcrumbs-separator {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.mantine-UnstyledButton-root {
  margin: 0 !important;

  .m-f07af9d2 {
    padding-left: 10px !important;
  }
}

.mantine-focus-auto
  .m-f0824112
  .mantine-NavLink-root
  .m-87cf2631
  .mantine-UnstyledButton-root {
  margin: 0 !important;
}
